/* You can add global styles to this file, and also import other style files */
// Montserrat font
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '../node_modules/ngx-spinner/animations/ball-scale-multiple.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Custom CSS for CDK Overlay (Mat Dialog)
.cdk-overlay-container {
  @apply overflow-y-hidden;
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
  @apply h-full;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container {
  @apply py-6;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane {
  @apply mx-5 bg-transparent overflow-y-scroll;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply rounded-2xl shadow-none;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.side-panel .mat-mdc-dialog-container {
  @apply py-0;
}

.cdk-overlay-container
  .cdk-global-overlay-wrapper
  .cdk-overlay-pane.side-panel
  .mat-mdc-dialog-container
  .mdc-dialog__surface {
  @apply rounded-none;
}

.mat-mdc-table .mat-mdc-row.table-row-hover {
  @apply hover:bg-gray-400-light;
}

.message-modal .mat-mdc-dialog-container .mdc-dialog__surface {
  @apply bg-transparent;
}

.table-component .mat-sort-header-content {
  @apply text-left;
}

.table-component .mdc-data-table__cell,
.table-component .mdc-data-table__header-cell {
  @apply p-4 font-montserrat;
}

.table-header .mdc-data-table__header-cell {
  @apply border-slate-300 text-base font-medium text-zinc-900;
}

.table-data .mdc-data-table__cell {
  @apply border-slate-200 text-base font-normal text-zinc-900;
}

.cdk-describedby-message-container .cdk-visually-hidden {
  @apply hidden;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.bottom-popup .mat-mdc-dialog-container {
  padding: 0;
}

.cdk-overlay-container
  .cdk-global-overlay-wrapper
  .cdk-overlay-pane.bottom-popup
  .mat-mdc-dialog-container
  .mdc-dialog__surface {
  @apply rounded-b-none rounded-t-3xl;
}

.cdk-overlay-container .cdk-global-overlay-wrapper .cdk-overlay-pane.bottom-popup {
  margin: 0;
}

// Custom Calendar Style
body .mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 375px;
  max-width: 375px;
  @apply px-4 pt-8 pb-7 rounded-3xl shadow-datePicker m-5;
}

body .mat-calendar-table-header,
body .mat-calendar-body-label {
  @apply text-gray-400;
}

body .mat-calendar-table-header th {
  font-size: 12px;
}

body .mat-calendar-controls {
  @apply mt-0;
}

body .mat-calendar-table-header-divider {
  @apply hidden;
}

body .mdc-button .mdc-button__label svg {
  @apply hidden;
}

body .mat-calendar-body-selected {
  @apply bg-sky-700;
}

body .mat-calendar-spacer {
  @apply hidden;
}

body .mat-calendar-header {
  @apply pt-0;
}

body .mat-calendar-header .mat-calendar-controls {
  @apply flex justify-between;
}

body .mat-calendar-previous-button {
  @apply order-1;
}

body .mat-calendar-period-button {
  @apply order-2;
}

body .mat-calendar-next-button {
  @apply order-3;
}

body .mat-mdc-menu-content {
  @apply p-0;
}

body .mdc-button {
  @apply font-montserrat font-semibold text-base;
}

body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  @apply border-none;
}

body .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical)::after {
  content: '';
  @apply w-1.5 h-1.5 bg-sky-700 rounded-full absolute bottom-0 left-1/2 -translate-x-2/4;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):not(.mat-calendar-body-in-range):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #ebf2f7;
}

body .mat-calendar-body-cell {
  font-size: 14px;
  @apply font-montserrat font-medium;
}

body .mat-calendar-body-in-range::before {
  background-color: #ebf2f7;
  @apply border-t border-b border-sky-700;
}

body .mat-calendar-body-cell-content {
  @apply text-slate-400;
}

body .mat-calendar-body-cell-content.mat-calendar-body-selected {
  @apply text-white;
}

.scroll-y-hide::-webkit-scrollbar {
  height: 0px;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2315202D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.625rem center;
  background-size: 1em;
}

@layer base {
  @font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  }

  // Inter font
  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url('./assets/fonts/Inter-Medium.ttf') format('truetype');
  }
}

@layer base {
  html,
  body {
    font-family: 'Montserrat', system-ui, sans-serif;
  }

  .qr-code-download canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

// Recaptcha Style
.grecaptcha-badge {
  @apply invisible;
}

// Custom Checkbox
body .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
  @apply bg-sky-700 border-sky-700;
}

body .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
body .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
body .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
  @apply bg-sky-800 border-sky-800;
}

body .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
body
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
body
  .mdc-checkbox:not(:disabled):active
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background {
  @apply bg-sky-800 border-sky-800;
}

body .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
  @apply bg-sky-800 border-sky-800;
}

body .mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  @apply bg-sky-700;
}

body th:has(mat-checkbox),
body td:has(mat-checkbox) {
  @apply w-10;
}

.container .container {
  @apply p-0;
}
